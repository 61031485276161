import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewEncapsulation,
    computed,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { UserprefService } from "../../../../../services/userpref.service";
import { S25Datefilter } from "../../../../s25-dateformat/s25.datefilter.service";
import { S25Util } from "../../../../../util/s25-util";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { S25Reservation } from "../../../ReservationI";

@TypeManagerDecorator("occurrence-time")
@Component({
    selector: "occurrence-time",
    template: ` @if (init) {
        <div class="occ-time-wrapper">
            <div>
                @if (isEndDate) {
                    {{ occurrence()?.eventEnd | timeFormat: timeFormat }}
                }
                @if (!isEndDate) {
                    {{ occurrence()?.eventStart | timeFormat: timeFormat }}
                }
            </div>
            @if (isEndDate && spansMidnight) {
                <div>({{ occurrence()?.eventEnd | dateFormat: dateFormat }})</div>
            }
        </div>
    }`,
    styles: [
        `
            .occ-time-wrapper {
                min-width: 149px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceTimeComponent {
    @Input() isEndDate: boolean = false;
    @Input() occ: S25Reservation;

    init: boolean = false;
    dateFormat: string;
    timeFormat: string;
    spansMidnight: boolean = false;
    occurrence = computed(() => {
        return this.occurrencesService.getOccurrences().find((rsrv) => rsrv.itemId === this.occ.itemId);
    });

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private occurrencesService: S25EventOccurrencesService,
    ) {}

    async ngOnInit() {
        const [dateFormat, timeFormat] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]);
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        if (this.isEndDate)
            this.spansMidnight =
                S25Util.date.getDate(this.occurrence().eventEnd) > S25Util.date.getDate(this.occurrence().eventStart);
        this.init = true;
        this.cd.detectChanges();
    }
}
