import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    computed,
    signal,
} from "@angular/core";
import { Table } from "../../../../s25-table/Table";
import { Bind } from "../../../../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../../../../main/type.map.service";
import { S25EventOccurrencesService } from "../../s25.event.occurrences.service";
import { UserprefService } from "../../../../../services/userpref.service";
import { S25Reservation } from "../../../ReservationI";
import { Event } from "../../../../../pojo/Event";
import Rsrv = Event.Reservation.States;
import ActionI = Table.ActionI;

@TypeManagerDecorator("occurrence-date")
@Component({
    selector: "occurrence-date",
    template: ` @if (init) {
        <div class="occ-expand-all" (click)="showDetails('autoClick')"></div>
        <div class="occ-date-wrapper">
            @if (!isOccuranceActive()) {
                <div>
                    {{ occurrence()?.eventStart | dateFormat: dateFormat }}
                </div>
            }
            @if (isOccuranceActive()) {
                <button
                    (click)="showDetails('manualClick')"
                    class="c-textButton toggle-details-button"
                    [class.expanded]="toggleShowDetails"
                >
                    <s25-ng-icon [type]="'caretRight'"></s25-ng-icon>
                    <div>{{ occurrence()?.eventStart | dateFormat: dateFormat }}</div>
                </button>
            }
        </div>
    }`,
    styles: [
        `
            .occ-date-wrapper {
                min-width: 149px;

                .toggle-details-button {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    svg {
                        transition: transform 0.3s;
                    }
                }
            }

            :host ::ng-deep .expanded svg {
                transform: rotate(90deg);
            }

            :host ::ng-deep .c-svgIcon:hover {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceDateComponent {
    @Input() occ: S25Reservation;
    @Input() eventState: number;
    @Input() canEdit?: boolean = false;
    @Input() eventId?: number;
    @Input() multiProfiles?: boolean = false;
    @Input() isSeparatedView?: boolean = false;
    @Output() toggleDetailsRow: EventEmitter<Table.NewRowModel> = new EventEmitter<Table.NewRowModel>();

    toggleShowDetails: boolean = false;
    init: boolean = false;
    dateFormat: string;
    occurrence = computed(() => {
        return this.occurrencesService.getOccurrences().find((rsrv) => rsrv.itemId === this.occ.itemId);
    });

    isOccuranceActive = signal<boolean>(false);

    isExpandAll = computed(() => {
        return this.occurrencesService.getExpandAll();
    });

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private occurrencesService: S25EventOccurrencesService,
    ) {}

    async ngOnInit() {
        const dateFormat = await UserprefService.getS25Dateformat();
        this.dateFormat = dateFormat;
        this.isOccuranceActive.set(this.isOccuranceActiveConditions());
        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    showDetails(caller: "autoClick" | "manualClick") {
        let action: ActionI;

        if (this.occ?.rowAction) {
            action = this.occ?.rowAction;
        } else {
            action = this.toggleShowDetails ? "delete" : "create";
        }

        // already showed detail, still clicked on expended All/collapse All, no action need
        if (
            (action === "delete" && caller === "autoClick" && !this.isExpandAll() && this.toggleShowDetails) ||
            (action === "create" && caller === "autoClick" && this.isExpandAll() && !this.toggleShowDetails)
        ) {
            action = "noAction";
            this.toggleShowDetails = !this.toggleShowDetails;
        }
        let table, row, allrows;
        if (window.innerWidth < 540) {
            // access HTML table elements when table is pivoted
            table = this.elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement;
            row = this.elementRef.nativeElement.parentElement.parentElement;
            allrows = table.children[1].children;
        } else {
            table = this.elementRef.nativeElement.offsetParent.offsetParent;
            row = this.elementRef.nativeElement.offsetParent.parentElement;
            allrows = table.children[2].children;
        }

        const tableData: Table.NewRowModel = {
            table: table,
            row: row,
            rowIndex: Array.from(allrows).indexOf(row) - (window.innerWidth < 540 ? 1 : 0),
            action: action,
            data: { occ: this.occurrence(), isCopy: false },
        };
        this.toggleShowDetails = !this.toggleShowDetails;
        tableData.caller = caller;
        this.toggleDetailsRow.emit(tableData);
        this.cd.detectChanges();
    }

    isOccuranceActiveConditions(): boolean {
        return !(
            ((this.eventState === Event.State.Ids.Denied ||
                this.eventState === Event.State.Ids.Cancelled ||
                this.multiProfiles) &&
                !this.isSeparatedView) ||
            this.occ.state === Rsrv.Cancelled ||
            this.occ.state === Rsrv.Exception
        );
    }
}
