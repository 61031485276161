import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    NgZone,
    ElementRef,
    OnDestroy,
    signal,
    computed,
} from "@angular/core";
import { S25ReservationUtil } from "../../../../utils/s25.reservation.util";
import { EventDataMicroI, S25Event } from "../../../../EventMicroI";
import { S25Util } from "../../../../../../util/s25-util";
import { EventOccUtil } from "../../s25.event.occurrence.util";
import { Range } from "../../../../../s25-slider/s25.slider.component";
import { S25EventOccurrencesService } from "../../../s25.event.occurrences.service";
import { Debounce } from "../../../../../../decorators/debounce.decorator";
import { TypeManagerDecorator } from "../../../../../../main/type.map.service";
import { S25LoadingApi } from "../../../../../s25-loading/loading.api";
import { EventMicroService } from "../../../../../../services/event.micro.service";
import { S25Reservation, S25RsReservation, S25RmReservation, rsrvType, ObjectType } from "../../../../ReservationI";
import { S25EventShareDataService } from "../../../s25.event.shared.data.service";
import { Subscription } from "rxjs";
import { Event } from "../../../../../../pojo/Event";
import { S25EditableStartEndDateTimeComponent } from "../../../../../s25-editable/s25-editable-start-end-datetime/s25.editable.start.end.date.time.component";
import { UserprefService } from "../../../../../../services/userpref.service";
import { S25Datefilter } from "../../../../../s25-dateformat/s25.datefilter.service";
import { WSReservations } from "../../../../EventMicroI";
import { ResourceService } from "../../../../../../services/resource.service";
import { Item } from "../../../../../../pojo/Item";
import { AvailWSResponse } from "../../../../../../services/resource.space.avail.service";
import { NotificationService } from "../../../../../../services/notification.service";
import { FlsService } from "../../../../../../services/fls.service";
import { AccessLevels, Fls } from "../../../../../../pojo/Fls";
import { TelemetryService } from "../../../../../../services/telemetry.service";
import WSSpacesAvail = AvailWSResponse.WSSpacesAvail;
import WSSpaceAvail = AvailWSResponse.WSSpaceAvail;
import DateElement = AvailWSResponse.DateElement;
import { S25PricingUtil } from "../../../../../s25-pricing/s25.pricing.util";
import { OccurrenceDef, RecordType, S25Profile } from "../../../../ProfileI";
import { S25ProfileUtil } from "../../../../utils/s25.profile.util";
import { ProfileApiI, ProfileI } from "../../../../../../pojo/ProfileI";
import { ProfileUtil, PatternEndChoices } from "../../../../../s25-datepattern/profile.util";
import { ReservationService } from "../../../../../../services/reservation.service";

@TypeManagerDecorator("occurrence-additional-details")
@Component({
    selector: "occurrence-additional-details",
    template: `<div #rowLoadingSpinner><s25-loading-inline [model]="{}"></s25-loading-inline></div>
        @if (init) {
            <div class="occ-details-wrapper" [class.read-only]="!editMode">
                @if (canEdit && hasPerm) {
                    @if (isCopy && profileCode) {
                        <div class="c-margin-bottom--half">
                            <span class="ngBold">Keep Repeating Pattern</span>
                            <s25-toggle-button
                                (modelValueChange)="onToggleChange($event)"
                                [modelValue]="repeatPatternToggle"
                                [trueLabel]="On"
                                [falseLabel]="Off"
                            ></s25-toggle-button>
                            @if (!repeatPatternToggle) {
                                <section class="cn-alert cn-alert--info cn-alert--notRealConflict">
                                    <section class="cn-alert__icon cn-icon cn-alert__icon--blue" name="alert--info">
                                        Warning: Selecting “No” will remove the repeating pattern from the event
                                        segment.
                                    </section>
                                </section>
                            }
                        </div>
                        @if (repeatPatternToggle) {
                            <span class="ngBold">{{ repetitionText }} </span>
                            <span class="c-margin-bottom--single c-margin-top--single"
                                >Extend the occurrence range of the existing repeat pattern:</span
                            >
                        }
                    }
                }

                <section class="date-time">
                    @if (!canEdit || !hasPerm) {
                        <s25-ng-editable-date
                            [val]="occ.eventStart || Date.now()"
                            [readOnly]="true"
                        ></s25-ng-editable-date>
                    }
                    @if (canEdit && hasPerm) {
                        @if (profileCode && isCopy && repeatPatternToggle) {
                            <section class="repeatPatrernSection">
                                @if (patternEndChoices === "date") {
                                    <span>Repeats through</span>
                                    <s25-ng-editable-date
                                        class="c-displayInlineBlock"
                                        [(val)]="throughDate"
                                        (valChange)="profileModelChange('date')"
                                        [alwaysEditing]="true"
                                    ></s25-ng-editable-date>
                                }

                                @if (patternEndChoices === "iterations") {
                                    <span>Ends after</span>
                                    <input
                                        [min]="profileModel.endsAfter"
                                        [max]="999"
                                        size="3"
                                        type="number"
                                        [(ngModel)]="endsAfter"
                                        (change)="profileModelChange('iterations', $event)"
                                        class="c-input"
                                    />
                                    iterations
                                }

                                @if (newOccsAvail.length > 0) {
                                    <div class="c-margin-top--single">
                                        <span>New occurrence date{{ newOccsAvail.length > 1 ? "s" : "" }}:</span>
                                        @for (r of newOccsAvail; track r?.evStartDt + i; let i = $index) {
                                            <div class="c-margin-left--double">
                                                {{ r.evStartDt | dateFormat: dateFormat }}
                                            </div>
                                        }
                                    </div>
                                }
                            </section>
                        }
                        @if (!isCopy || (profileCode && !repeatPatternToggle) || !profileCode) {
                            <s25-ng-editable-start-end-datetime
                                [startDatetime]="occ.eventStart || Date.now()"
                                (startDatetimeChange)="onChangeDate($event, 'start')"
                                [endDatetime]="occ.eventEnd || Date.now()"
                                (endDatetimeChange)="onChangeDate($event, 'end')"
                                [alwaysEditing]="true"
                                [noTimeUpdated]="true"
                            >
                            </s25-ng-editable-start-end-datetime>
                            <span class="c-margin-bottom--half"></span>
                        }
                    }

                    @if (initSlider) {
                        <s25-ng-slider
                            [model]="{
                                type: 'time',
                                ranges: sliderRange,
                                step: 0.25,
                            }"
                            (onChange)="onSliderChange($event)"
                        ></s25-ng-slider>
                    }
                </section>
                @if (isOverlap()) {
                    <section class="cn-alert cn-alert--info cn-alert--notRealConflict">
                        <section class="cn-alert__icon cn-icon cn-alert__icon--blue" name="alert--info">
                            Warning: You have overlapping occurrences. Consider adjusting occurrence dates or durations.
                        </section>
                    </section>
                }

                @if (showRepeatPatternWarmingMsg) {
                    <section class="cn-alert cn-alert--info cn-alert--notRealConflict">
                        <section class="cn-alert__icon cn-icon cn-alert__icon--blue" name="alert--info">
                            <div>Warning:</div>
                            <div>{{ repetitionText }}</div>
                            <span
                                >With this change to date/time, the segment will no longer have a repeating pattern.
                            </span>
                        </section>
                    </section>
                }
                @if (isDatesChanged && !isCopy && assignAllowed()) {
                    <section class="date-time-buttons">
                        <button
                            class="aw-button aw-button--outline c-margin-right--single"
                            (click)="onchangeDateCancel()"
                        >
                            Cancel
                        </button>
                        <button class="aw-button aw-button--primary c-margin-right--single" (click)="save()">
                            Save
                        </button>
                    </section>
                }
                <section class="locations-resources">
                    @if (occ.locations?.length > 0) {
                        <div>
                            @for (arr of objectRsrvArr; track arr) {
                                <div>
                                    @for (l of occ.locations[0][arr]; track l?.itemId; let idx = $index) {
                                        <div>
                                            @if (occ.locations[0][arr]["length"] > 0) {
                                                <div class="location {{ arr }}">
                                                    <div>
                                                        <s25-item-space
                                                            [modelBean]="{ itemId: l.itemId, itemName: l.itemName }"
                                                            [includeTypeIcon]="true"
                                                            [pendingStyleClass]="
                                                                arr === 'requested' || arr === 'draft' ? '-pending' : ''
                                                            "
                                                        ></s25-item-space>
                                                        <div>
                                                            Shared
                                                            <s25-ng-checkbox
                                                                [ariaLabel]="'Select' + l.itemName"
                                                                [modelValue]="l.isShare"
                                                                [disabled]="!canEdit || fls.EVENT_SHARE !== 'F'"
                                                                (modelValueChange)="onSharedChange($event, arr, idx)"
                                                            >
                                                            </s25-ng-checkbox>
                                                        </div>
                                                        @if (canEdit && l.hasPerm) {
                                                            <div>
                                                                <button
                                                                    class="aw-button aw-button--danger--transparent"
                                                                    (click)="
                                                                        setConfirmDeleteMsg(
                                                                            l.itemName,
                                                                            'space',
                                                                            idx,
                                                                            arr
                                                                        )
                                                                    "
                                                                    [disabled]="
                                                                        deleteWarning().index === idx &&
                                                                        deleteWarning().objType === 'space' &&
                                                                        deleteWarning().rsrvType === arr
                                                                    "
                                                                >
                                                                    {{
                                                                        arr === "requested"
                                                                            ? "Cancel Request"
                                                                            : "Remove"
                                                                    }}
                                                                </button>
                                                                @if (hasConflict && isConflict(l.itemId, "location")) {
                                                                    <span class="ngRed"> Conflict</span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    @if (
                                                        deleteWarning().index === idx &&
                                                        deleteWarning().objType === "space" &&
                                                        deleteWarning().rsrvType === arr
                                                    ) {
                                                        <s25-ng-delete-inline
                                                            [confirmMsg]="deleteWarning().msg"
                                                            [listItems]="deleteWarning().listItems"
                                                            (onConfirm)="
                                                                isCopy
                                                                    ? onCopyOccObjectDelete('location', l.itemId)
                                                                    : onObjectDelete(
                                                                          'location',
                                                                          l.itemId,
                                                                          arr,
                                                                          l,
                                                                          $event
                                                                      )
                                                            "
                                                        ></s25-ng-delete-inline>
                                                    } @else {
                                                        @if (l.layout.itemName) {
                                                            <label>
                                                                Layout:
                                                                @if (
                                                                    l.locationLayout?.length > 0 && canEdit && l.hasPerm
                                                                ) {
                                                                    <span>
                                                                        <select
                                                                            class="cn-form__control"
                                                                            id="layout{{ l.layout.itemId }}"
                                                                            (change)="onLayoutChange($event, idx, arr)"
                                                                        >
                                                                            @for (
                                                                                layout of l.locationLayout;
                                                                                track layout.layout_id
                                                                            ) {
                                                                                <option
                                                                                    [value]="layout.layout_id"
                                                                                    [selected]="
                                                                                        layout.layout_id ===
                                                                                        l.layout.itemId
                                                                                    "
                                                                                >
                                                                                    {{ layout.layout_name }}
                                                                                </option>
                                                                            }
                                                                        </select>
                                                                    </span>
                                                                }
                                                                @if (!canEdit || !l.hasPerm) {
                                                                    {{ l.layout.itemName }}
                                                                }
                                                            </label>
                                                        }
                                                        <div [class.c-margin-top--single]="editMode">
                                                            <label>
                                                                Instructions:
                                                                <s25-ng-editable-textarea
                                                                    [(val)]="l.instructions"
                                                                    [readOnly]="!canEdit || !l.hasPerm"
                                                                    placeholder="Add instructions"
                                                                    [hasCommit]="!isCopy"
                                                                    [hasCancelButton]="!isCopy"
                                                                    [hasCommitButton]="!isCopy"
                                                                    commitButtonText="Save"
                                                                    cancelButtonText="Cancel"
                                                                    (valChange)="
                                                                        onTextareaChange($event, 4, l, arr, idx)
                                                                    "
                                                                >
                                                                </s25-ng-editable-textarea>
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                                @if (
                                                    l.assignBuffer?.bufferWarningMsg &&
                                                    l.assignBuffer?.bufferWarningMsg !== " "
                                                ) {
                                                    <section class="cn-alert cn-alert--danger">
                                                        {{ l.assignBuffer.bufferWarningMsg }}
                                                    </section>
                                                }
                                            }
                                        </div>
                                    }
                                    <!--  end arr -->
                                </div>
                            }
                        </div>
                    }
                    @if (occ.resources?.length > 0) {
                        <div>
                            @for (arr of objectRsrvArr; track arr) {
                                <div>
                                    @for (r of occ.resources[0][arr]; track r?.itemId; let i = $index) {
                                        <div>
                                            @if (occ.resources[0][arr]["length"] > 0) {
                                                <div class="resource">
                                                    <div>
                                                        <s25-item-resource
                                                            [modelBean]="{ itemId: r.itemId, itemName: r.itemName }"
                                                            [includeTypeIcon]="true"
                                                            [pendingStyleClass]="
                                                                arr === 'requested' || arr === 'draft' ? '-pending' : ''
                                                            "
                                                        ></s25-item-resource>
                                                        @if (canEdit && r.hasPerm) {
                                                            <div>
                                                                <button
                                                                    class="aw-button aw-button--danger--transparent"
                                                                    (click)="
                                                                        setConfirmDeleteMsg(
                                                                            r.itemName,
                                                                            'resource',
                                                                            i,
                                                                            arr
                                                                        )
                                                                    "
                                                                    [disabled]="
                                                                        deleteWarning().index === i &&
                                                                        deleteWarning().objType === 'resource' &&
                                                                        deleteWarning().rsrvType === arr
                                                                    "
                                                                >
                                                                    {{
                                                                        arr === "requested"
                                                                            ? "Cancel Request"
                                                                            : "Remove"
                                                                    }}
                                                                </button>
                                                                @if (hasConflict && isConflict(r.itemId, "resource")) {
                                                                    <span class="ngRed"> Conflict</span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    @if (
                                                        deleteWarning().index === i &&
                                                        deleteWarning().objType === "resource" &&
                                                        deleteWarning().rsrvType === arr
                                                    ) {
                                                        <s25-ng-delete-inline
                                                            [confirmMsg]="deleteWarning().msg"
                                                            [listItems]="deleteWarning().listItems"
                                                            (onConfirm)="
                                                                isCopy
                                                                    ? onCopyOccObjectDelete('resource', r.itemId)
                                                                    : onObjectDelete(
                                                                          'resource',
                                                                          r.itemId,
                                                                          arr,
                                                                          r,
                                                                          $event
                                                                      )
                                                            "
                                                        ></s25-ng-delete-inline>
                                                    } @else {
                                                        <label>
                                                            Quantity:
                                                            <s25-ng-editable-number
                                                                [val]="r.quantity"
                                                                [readOnly]="!canEdit || !r.hasPerm"
                                                                [min]="1"
                                                                [max]="r?.currentStockLevel || 65536"
                                                                hasCommit="true"
                                                                hasCancelButton="true"
                                                                hasCommitButton="true"
                                                                commitButtonText="Save"
                                                                cancelButtonText="Cancel"
                                                                (valChange)="
                                                                    onQuantityChange($event, r.quantity, i, r, arr)
                                                                "
                                                            >
                                                            </s25-ng-editable-number>
                                                        </label>
                                                        <div>
                                                            <label>
                                                                Instructions:
                                                                <s25-ng-editable-textarea
                                                                    [(val)]="r.instructions"
                                                                    [readOnly]="!canEdit || !r.hasPerm"
                                                                    placeholder="Add instructions"
                                                                    [hasCommit]="!isCopy"
                                                                    [hasCancelButton]="!isCopy"
                                                                    [hasCommitButton]="!isCopy"
                                                                    commitButtonText="Save"
                                                                    cancelButtonText="Cancel"
                                                                    (valChange)="onTextareaChange($event, 6, r, arr, i)"
                                                                >
                                                                </s25-ng-editable-textarea>
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </section>

                <section class="c-margin-top--single c-margin-bottom--single">
                    <label>
                        Occurrence Comments:
                        <s25-ng-editable-textarea
                            [val]="occurrence()?.comments"
                            [readOnly]="!canEdit"
                            placeholder="Add comments"
                            [hasCommit]="!isCopy"
                            [hasCancelButton]="!isCopy"
                            [hasCommitButton]="!isCopy"
                            commitButtonText="Save"
                            cancelButtonText="Cancel"
                            (valChange)="onOccCommentsChange($event)"
                        >
                        </s25-ng-editable-textarea>
                    </label>
                </section>
                @if (isCopy && assignAllowed()) {
                    <section class="buttons">
                        <button
                            class="aw-button aw-button--primary c-margin-right--single"
                            [disabled]="copyOccSaveButtonDisabled"
                            (click)="save()"
                        >
                            Save
                        </button>
                        <button class="aw-button aw-button--outline c-margin-right--single" (click)="cancel()">
                            Cancel
                        </button>
                    </section>
                }

                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
            </div>
        }`,
    styles: `
        @container details-wrapper (width < 700px) {
            .date-time {
                height: 32em;
                flex-direction: column;

                .c-start-end-datetime-picker--wrapper > div,
                p {
                    margin: auto !important;
                }
            }

            .locations-resources > div {
                width: 90%;
            }
        }

        ::ng-deep s25-toggle-button .toggle__label--2 {
            margin-left: 1.75em !important;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OccurrenceAdditionalDetailsComponent implements OnDestroy, OnInit {
    @Input() occ: S25Reservation;
    @Input() isCopy: boolean;
    @Input() canEdit?: boolean;
    @Input() eventId?: number;
    @Input() profileId?: number;
    @Input() selectedItems?: number[];
    @Input() eventState?: Event.State.Ids;
    @Input() copyInitOcc?: S25Reservation;
    @Input() editMode?: boolean;

    @Output() onSave = new EventEmitter<{ id: number }>();
    @Output() onCancel = new EventEmitter<void>();
    @Output() refreshF = new EventEmitter<void>();
    @ViewChild(S25EditableStartEndDateTimeComponent) editDateTimeComponent: S25EditableStartEndDateTimeComponent;

    @ViewChild("rowLoadingSpinner") rowLoadingSpinner: ElementRef;

    initOcc: S25Reservation;
    init: boolean;
    objectRsrvArr = ["reserved", "draft", "requested"];
    hasConflict: boolean;
    sliderRange: Range[];
    initSliderRange: Range[];
    findResourcesConflict: any = [];
    findLocationConflict: any = [];
    event: S25Event;
    private selectedItemsSubscription: Subscription;
    isDatesChanged: boolean = false;
    dateFormat: string;
    timeFormat: string;
    deleteWarning = signal({ index: null, msg: null, listItems: null, objType: null, rsrvType: null });
    hasPerm: boolean;
    fls: Fls;
    occurrence = computed(() => {
        return this.occurrencesService.getOccurrences().find((rsrv) => rsrv.itemId === this.occ.itemId);
    });
    initSlider: boolean = true;
    isOverlap = signal<boolean>(false);
    assignAllowed = signal<boolean>(true);
    private lock: Promise<any> = Promise.resolve();
    profileCode: string = undefined;
    profileModel: ProfileI = {};
    patternEndChoices: PatternEndChoices; //none, date, iterations
    repetitionText: string;
    throughDate: Date;
    endsAfter: number;
    newOccsAvail: any[] = [];
    newProfileCode: string;
    copyOccSaveButtonDisabled: boolean = false;
    showRepeatPatternWarmingMsg: boolean = false;
    repeatPatternToggle: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private occurrencesService: S25EventOccurrencesService,
        private elementRef: ElementRef,
        private shareDataService: S25EventShareDataService,
    ) {}

    sortByRsrvName(arr: S25Reservation[]) {
        if (!arr || arr.length === 0) return;
        arr.sort((a, b) => (a.itemName > b.itemName ? 1 : a.itemName < b.itemName ? -1 : 0));
    }

    async ngOnInit() {
        setTimeout(() => {
            this.rowLoadingSpinner && S25LoadingApi.init(this.rowLoadingSpinner.nativeElement);
        });
        const [dateFormat, timeFormat, fls] = await Promise.all([
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
            FlsService.getFls(),
        ]);
        this.fls = fls;
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.occ.locations.forEach((item) => Object.values(item).forEach(this.sortByRsrvName));
        this.occ.resources.forEach((item) => Object.values(item).forEach(this.sortByRsrvName));
        if (!this.initOcc) this.initOcc = S25Util.deepCopy(this.occ);
        this.setSliderRange();
        this.selectedItemsSubscription = this.shareDataService.getSelectedItems().subscribe((items) => {
            this.selectedItems = items;
        });

        this.hasPerm = S25ReservationUtil.checkObjHasPerm(this.occurrencesService.getOccurrences());

        this.eventState === Event.State.Ids.Draft
            ? (this.objectRsrvArr = ["draft"])
            : (this.objectRsrvArr = ["reserved", "requested"]);

        this.profileCode = S25ProfileUtil.getProfileCode(this.occurrencesService.getProfiles(), this.occ.profileId);
        if (this.profileCode) {
            this.throughDate = ProfileUtil.getProfileCodeThroughDate(this.profileCode);
            this.profileModel = ProfileUtil.getProfileModel(null, this.profileCode, this.throughDate, null);
            this.patternEndChoices = this.profileModel.repeatEnd;
            this.repetitionText = EventOccUtil.getRepetitionText(this.profileCode);
            this.endsAfter = this.profileModel.endsAfter;
            this.copyOccSaveButtonDisabled = true;
            this.repeatPatternToggle = true;
        }
        if (this.isCopy) this.checkOverlapping(this.occ.eventStart);
        setTimeout(() => {
            this.rowLoadingSpinner && S25LoadingApi.destroy(this.rowLoadingSpinner.nativeElement);
        });

        this.init = true;
        this.cd.detectChanges();
    }

    async onTextareaChange(
        newVal: string,
        itemTypeId: number,
        obj?: S25RmReservation | S25Reservation,
        type?: rsrvType,
        index?: number,
    ) {
        if (this.isCopy) return false;
        // update object insturctions
        let updateItem;
        if (itemTypeId === 4) {
            this.occ.locations[0][type][index].instructions = newVal;
            updateItem = this.occ.locations[0][type][index];
            this.updateObject(updateItem, type, 4);
        } else {
            this.occ.resources[0][type][index].instructions = newVal;
            updateItem = this.occ.resources[0][type][index];
            this.updateObject(updateItem, type, 6);
        }
    }

    // All space and resource reservations input with no label (draft,  request  & reserved)
    async onObjectDelete(
        type: ObjectType,
        id: number,
        nodeName: string,
        removeItem: S25RmReservation | S25RsReservation,
        confirmed: boolean,
    ) {
        this.deleteWarning.set({ index: null, msg: null, listItems: null, objType: null, rsrvType: null });
        if (!confirmed) return;

        // Create a lock that ensures only one operation happens at a time
        const currentLock = this.lock;
        this.lock = this.lock.then(async () => {
            let accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
            if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId); //  apply to selected rsrv want to apply to inline edit, if current rsrv not in the selected, added to it

            const restult = await EventOccUtil.normalizeDeleteRsrvWSData(
                removeItem as S25RmReservation,
                null,
                nodeName,
                this.occ,
                type,
            );

            const reservations = [];
            for (const item of accuUpdateItems) {
                reservations.push({
                    rsrvId: item, //rsrvId
                    remove: restult.remove,
                });
            }

            let payload: EventDataMicroI = {
                items: [
                    {
                        kind: "event",
                        id: this.eventId,
                        profiles: [
                            {
                                profileId: this.profileId,
                                reservations: reservations,
                            },
                        ],
                    },
                ],
            };

            this.setLoading(true);
            TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", "Remove" + type);
            const [ok, errors] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
            this.setLoading(false);
            if (ok) {
                if (ok?.content?.errors) {
                    for (let error of ok?.content?.errors) {
                        NotificationService.post(error.message);
                    }
                } else {
                    if (type === "location") {
                        S25ReservationUtil.removeLocationsById(this.occ, [id]);
                    } else {
                        S25ReservationUtil.removeResourceById(this.occ, [id]);
                    }

                    this.occurrencesService.getEvent(this.eventId, false, ok);
                    this.cd.detectChanges();
                    this.refreshF.emit();
                }
            }
            if (errors)
                return S25Util.showError(errors, "There was an error while attempting to delete this reservation.");
        });
        // Wait for the current operation to complete before unlocking
        await currentLock;
    }

    async onLayoutChange(e: any, index: any, type: rsrvType) {
        this.occ.locations[0][type][index].layout.itemId = parseInt(e.target.value);
        this.occ.locations[0][type][index].layout.itemName = e.target.selectedOptions[0].text;
        const findLayout = this.occ.locations[0][type][index].locationLayout.find(
            (l: any) => l.layout_id === parseInt(e.target.value),
        );
        if (findLayout && findLayout?.layout_instruction)
            this.occ.locations[0][type][index].instructions = findLayout?.layout_instruction;
        let updateItem = this.occ.locations[0][type][index];
        this.updateObject(updateItem, type, 4);
    }

    async onChangeDate(newDate: Date, text: "start" | "end") {
        this.isDatesChanged = true;
        this.newDateSet(newDate, text);
        if (text === "start") this.checkOverlapping(newDate);
        if (
            (this.profileCode && this.repeatPatternToggle && !this.isCopy) ||
            (this.profileCode && !this.repeatPatternToggle && this.isCopy)
        )
            this.showRepeatPatternWarmingMsg = true;
        this.cd.detectChanges();
    }

    async onchangeDateCancel() {
        this.occ = S25Util.clone(this.initOcc);
        await this.editDateTimeComponent.refreshDatetimes({
            startDatetime: this.occ.eventStart,
            endDatetime: this.occ.eventEnd,
        });
        this.isDatesChanged = false;
        this.showRepeatPatternWarmingMsg = false;
        this.cd.detectChanges();
    }

    @Debounce(300) // Avoid double calls
    onSliderChange(e: any) {
        let date: any = [{ startDt: e.ranges[1].start, endDt: e.ranges[1].end }];
        this.checkObjectsAvailability(date, this.fls);

        // this.initSliderRange.forEach((range: any, index: number ) => {
        //      // Update the start property of the range in initMode
        //      range.start = (new Date(range.start)).setHours( e.ranges[index].start);
        //      range.start = (new Date(range.start)).setMinutes( (e.ranges[index].start % 1) * 60);
        //      range.start = new Date(range.start);

        //      range.end = (new Date(range.end)).setHours( e.ranges[index].end);
        //      range.end = (new Date(range.end)).setMinutes( (e.ranges[index].end % 1) * 60);
        //      range.end = new Date(range.end);
        //  });
        //   console.log('tempSliderRange', this.initSliderRange);
    }

    async onQuantityChange(newVal: number, initVal: number, index: number, r: S25RsReservation, type: rsrvType) {
        const accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
        if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId);
        const rvrs = this.occurrencesService.getOccurrences().filter((item) => accuUpdateItems.includes(item.itemId));
        const datesArr = S25ReservationUtil.getReservationsStartEndDates(rvrs);
        const checkItem = [{ itemId: r.itemId, quantity: newVal }];
        TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", "ChangeQuantity");
        const [result, error] = await S25Util.Maybe(
            ResourceService.getResourceDatesAvailability(checkItem, datesArr, this.eventId, this.profileId),
        );

        if (result) {
            const resultRes = result.resources.resource[0];
            const availQyt =
                resultRes.dates[0]?.stock_level || resultRes.dates[0]?.stock_level === 0
                    ? resultRes.dates[0].stock_level
                    : 3665;
            const noRequest = resultRes.dates.filter((item: DateElement) => item.perm_name === "No Request");
            const hasCoflict = resultRes.has_conflicts !== "F";
            this.occ.resources[0][type][index].quantity = this.initOcc.resources[0][type][index].quantity;
            if (availQyt >= newVal && noRequest.length === 0 && !hasCoflict) {
                this.occ.resources[0][type][index].quantity = newVal;
                let updateItem = this.occ.resources[0][type][index];
                this.updateObject(updateItem, type, Item.Ids.Resource);
            } else {
                this.occ.resources[0][type][index].quantity = initVal;
                this.cd.detectChanges();
                if (hasCoflict) {
                    alert("Cannot be saved due to conflicts. Please fix any conflicts before continuing.");
                } else if (noRequest.length > 0) {
                    alert(
                        "You cannot request or reserve this resource on the selected date. Please adjust or exclude the occurrence date(s)",
                    );
                } else if (newVal > availQyt) {
                    alert("Please enter a integer size less than or equal to " + availQyt);
                }
                return false;
            }
        }

        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
        return false;
    }

    async newDateSet(newDate: Date, text: "start" | "end") {
        let getStartDiffMinutes: number = S25Util.date.diffMinutes(this.initOcc.eventStart, this.occ.eventStart);
        let getEndDiffMinutes: number = S25Util.date.diffMinutes(this.initOcc.eventEnd, this.occ.eventEnd);

        if (text === "start") {
            this.occ.eventStart = newDate;
            //start date changed, check   assignBuffer
            const hasAssignBuffer = S25ReservationUtil.checkObjHasAssignBuffer(this.occ.locations, true);
            if (hasAssignBuffer && this.fls.SPACE_ASSIGN_OVER !== "F") {
                await S25ReservationUtil.checkAssignBuffer(newDate, this.occ.locations);
                this.assignAllowed.set(await !S25ReservationUtil.checkObjHasAssignBuffer(this.occ.locations, false));
            }
        } else {
            getEndDiffMinutes = S25Util.date.diffMinutes(this.initOcc.eventEnd, newDate);
            this.occ.eventEnd = newDate;
        }

        if (this.occ?.preEventStart)
            this.occ.preEventStart = S25Util.date.addMinutes(this.initOcc.preEventStart, getStartDiffMinutes);
        if (this.occ?.setupStart)
            this.occ.setupStart = S25Util.date.addMinutes(this.initOcc.setupStart, getStartDiffMinutes);
        if (this.occ?.postEventEnd)
            this.occ.postEventEnd = S25Util.date.addMinutes(this.initOcc.postEventEnd, getEndDiffMinutes);
        if (this.occ?.takeDownEnd)
            this.occ.takeDownEnd = S25Util.date.addMinutes(this.initOcc.takeDownEnd, getEndDiffMinutes);
    }

    @Debounce(300) // Avoid double calls
    checkObjectsAvailability(date: [], fls: Fls) {
        return S25ReservationUtil.checkObjectsDatesAvailability(
            this.eventId,
            this.profileId,
            date,
            this.occ.locations,
            this.occ.resources,
        ).then((data: { spaces: WSSpacesAvail[]; resources: any }[]) => {
            data.forEach((item: any) => {
                // Check if the object has properties named "resources" and "spaces"
                if ("resources" in item) {
                    this.findResourcesConflict = item.resources.resource.filter(function (i: any) {
                        return i.has_conflicts === "T";
                    });
                }

                if ("spaces" in item) {
                    this.findLocationConflict = item.spaces.space.filter(function (i: WSSpaceAvail) {
                        return i.has_conflicts === "T" && !S25ReservationUtil.conflictOverride(i, fls);
                    });
                }
            });

            if (this.findLocationConflict.length > 0 || this.findResourcesConflict.length > 0) {
                this.hasConflict = true;
                this.cd.detectChanges();
                alert("Cannot be saved due to conflicts. Please fix any conflicts before continuing");
            } else {
                /// get payload ready then save data when the service available
                this.hasConflict = false;
                this.cd.detectChanges();
            }
        });
    }

    // save date changes or copy occ use event mirco service
    async save() {
        let date: any = [
            {
                startDt: this.occ?.setupStart || this.occ?.preEventStart || this.occ.eventStart,
                endDt: this.occ?.takeDownEnd || this.occ?.postEventEnd || this.occ.eventEnd,
            },
        ];
        let data = this.normalizeSaveData(true);

        if (this.profileCode && this.newProfileCode && this.newOccsAvail.length > 0) {
            const currentProfile = data.items[0].profiles[0];
            currentProfile.occurrenceDefn.profileCode = this.newProfileCode;
            const firstRsrv: WSReservations = currentProfile.reservations[0];
            await this.setAdditionalNewOccs(currentProfile, firstRsrv);
        }

        await this.checkObjectsAvailability(date, this.fls);

        if (!this.hasConflict) {
            this.setLoading(true);
            TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", this.isCopy ? "OccCopy" : "EditMode");
            const [ok, error] = await S25Util.Maybe(EventMicroService.microPutEventDetail(data, this.eventId));
            this.setLoading(false);
            if (ok) {
                if (ok?.content?.errors || ok?.content?.messages) {
                    for (let error of ok?.content?.errors || ok?.content?.messages) {
                        NotificationService.post(error.message);
                    }
                } else {
                    this.initSlider = false; // reset slider
                    this.cd.detectChanges();
                    this.occurrencesService.getEvent(this.eventId, false, ok); //fetch newUpateData
                    this.isDatesChanged = false;
                    this.isOverlap.set(false);
                    this.setSliderRange();
                    this.initSlider = true;
                    this.cd.detectChanges();
                }
                if (this.isCopy) this.onSave.emit();
            }

            if (error) return S25Util.showError(error, "There was an error while attempting to copy this reservation.");
        }
    }

    findDuplicateOcc(occList: WSReservations[]) {
        if ((this.isCopy && !this.profileCode) || (this.isCopy && this.profileCode && !this.repeatPatternToggle)) {
            const getOccList = this.occurrencesService.getOccurrences();
            getOccList.push(this.copyInitOcc);
            occList = S25ProfileUtil.normalizeRsrvWSData(getOccList, RecordType.FreeForm);
        }
        const eventStartSet = new Set();
        const duplicates: string[] = [];
        occList.forEach((event) => {
            const eventStart = S25Util.date.toS25ISODateTimeStr(event.evStartDt);
            if (eventStartSet.has(eventStart)) {
                duplicates.push(eventStart);
            } else {
                eventStartSet.add(eventStart);
            }
        });
        return duplicates;
    }

    findExistingOccurrence(occurrencesList: S25Reservation[]) {
        return occurrencesList.find(
            (item) =>
                S25Util.date.toS25ISODateTimeStr(item.eventStart) ===
                S25Util.date.toS25ISODateTimeStr(this.occ.eventStart),
        );
    }

    hasDuplicateDate(findOcc: S25Reservation) {
        const isSameStartDate =
            S25Util.date.toS25ISODateTimeStr(this.occ.eventStart) ===
            S25Util.date.toS25ISODateTimeStr(this.initOcc.eventStart);
        if (isSameStartDate) return true;
        if (findOcc) return !this.isCopy || this.occ.itemId === findOcc.itemId;
    }

    cancel() {
        this.init = false;
        delete this.occ;
        this.cd.detectChanges();
        this.occ = this.initOcc;
        this.setSliderRange();
        this.init = true;
        this.cd.detectChanges();
        if (this.isCopy) this.onCancel.emit();
    }

    isConflict(id: number, type: string) {
        if (type === "location") {
            let find = this.findLocationConflict.find((f: any) => {
                return f && f.space_id === id;
            });
            return find;
        } else {
            let find = this.findResourcesConflict.find((f: any) => {
                return f && f.resource_id === id;
            });
            return find;
        }
    }

    setSliderRange() {
        this.zone.run(() => {
            this.sliderRange = [
                {
                    start: this.occ.eventStart,
                    end: this.occ.eventEnd,
                    startLabel: "Start",
                    endLabel: "End",
                    hideStart: false,
                    hideEnd: false,
                },
            ];
            if (this.occ.preEventStart || this.occ.postEventEnd) {
                this.sliderRange.push({
                    start: this.occ?.preEventStart || this.occ.eventStart,
                    end: this.occ?.postEventEnd || this.occ.eventEnd,
                    startLabel: "Pre-Event",
                    endLabel: "Post-Event",
                    hideStart: this.occ.preEventStart ? false : true,
                    hideEnd: this.occ.postEventEnd ? false : true,
                });
            }
            if (this.occ.setupStart || this.occ.takeDownEnd) {
                this.sliderRange.push({
                    start: this.occ?.setupStart || this.occ.eventStart,
                    end: this.occ?.takeDownEnd || this.occ.eventEnd,
                    startLabel: "Setup",
                    endLabel: "Takedown",
                    hideStart: this.occ.setupStart ? false : true,
                    hideEnd: this.occ.takeDownEnd ? false : true,
                });
            }
            this.initSliderRange = S25Util.deepCopy(this.sliderRange);
        });
    }

    // update Ojbect uses  Reservation Micro  service
    async updateObject(updateItem: any, type: rsrvType, itemTypeId: number) {
        let event = this.occurrencesService.S25Event;
        let reservations: any[] = [];
        let accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
        if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId); //  apply to selected rsrv want to apply to inline edit, if current rsrv not in the selected, added to it

        if (itemTypeId === 4) {
            for (const item of accuUpdateItems) {
                //check if rsrv has the update object
                let find = await S25ReservationUtil.getObjectRsrvId(event, item, updateItem.itemId, itemTypeId);
                if (find)
                    reservations.push({
                        rsrvId: item, //rsrvId
                        spaces: [
                            {
                                [type]: [
                                    {
                                        spaceId: updateItem.itemId,
                                        layoutId: updateItem?.layout?.itemId,
                                        share: updateItem.isShare,
                                        instructions: updateItem.instructions,
                                        rating: updateItem.rating,
                                    },
                                ],
                            },
                        ],
                    });
            }
        } else {
            for (const item of accuUpdateItems) {
                let find = await S25ReservationUtil.getObjectRsrvId(event, item, updateItem.itemId, itemTypeId);
                if (find)
                    reservations.push({
                        rsrvId: item, //rsrvId
                        resources: [
                            {
                                [type]: [
                                    {
                                        resourceId: updateItem.itemId,
                                        quantity: updateItem.quantity,
                                        instructions: updateItem.instructions,
                                    },
                                ],
                            },
                        ],
                    });
            }
        }
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: reservations,
                        },
                    ],
                },
            ],
        }; //end payload

        this.setLoading(true);
        TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", "EditMode");
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        if (ok) {
            if (ok?.content?.errors || ok?.content?.messages) {
                for (let error of ok?.content?.errors || ok?.content?.messages) {
                    NotificationService.post(error.message);
                }
            } else {
                this.occurrencesService.getEvent(this.eventId, false, ok); //fetch newUpateData
                this.cd.detectChanges();
            }
        }
        this.setLoading(false);

        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    setLoading(yes: boolean) {
        if (yes) {
            S25LoadingApi.init(this.elementRef.nativeElement);
        } else {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
        }
        this.cd.detectChanges();
    }

    setConfirmDeleteMsg(itemName: string, objType: "space" | "resource", index: number, rsrvType: rsrvType) {
        let affectedOccs = [];
        if (this.selectedItems.length) {
            const occData: any = { dateFormat: this.dateFormat, timeFormat: this.timeFormat };
            const occs = this.occurrencesService.getOccurrences();
            const massagedOccs = this.selectedItems
                ?.map((rsrvId) => {
                    const occMatch = occs?.find((occ) => occ.itemId === rsrvId);
                    if (occMatch) {
                        occData[rsrvId] = {
                            rsrvStartDt: occMatch.eventStart,
                            rsrvEndDt: occMatch.eventEnd,
                        };
                        return { occurrence: occMatch.eventStart, rsrvId: rsrvId };
                    }
                })
                ?.filter(Boolean);
            S25PricingUtil.formatOccDates(massagedOccs, occData);
            affectedOccs = massagedOccs?.map((occ) => occ.occurrence);
        } else {
            affectedOccs.push(S25Datefilter.transform(this.occ.eventStart, this.dateFormat));
        }

        this.deleteWarning.set({
            index: index,
            msg: `Are you sure you want to remove ${itemName} from these occurrences?`,
            listItems: affectedOccs,
            objType: objType,
            rsrvType: rsrvType,
        });
    }

    ngOnDestroy() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.unsubscribe();
        }
    }

    onCopyOccObjectDelete(type: ObjectType, id: number) {
        type === "location"
            ? S25ReservationUtil.removeLocationsById(this.occ, [id])
            : S25ReservationUtil.removeResourceById(this.occ, [id]);
        this.cd.detectChanges();
    }

    async onOccCommentsChange(newVal: string) {
        if (this.isCopy) return false;
        let reservations: Partial<WSReservations>[] = [];
        let accuUpdateItems: number[] = Object.values(S25Util.clone(this.selectedItems));
        if (!accuUpdateItems.includes(this.occ.itemId)) accuUpdateItems.push(this.occ.itemId); //  apply to selected rsrv want to apply to inline edit, if current rsrv not in the selected, added to it
        for (const item of accuUpdateItems) {
            reservations.push({
                rsrvId: item, //rsrvId
                comments: newVal,
            });
        }
        let payload: EventDataMicroI = {
            items: [
                {
                    kind: "event",
                    id: this.eventId,
                    profiles: [
                        {
                            profileId: this.profileId,
                            reservations: reservations,
                        },
                    ],
                },
            ],
        }; //end payload
        this.setLoading(true);
        TelemetryService.sendWithSub("EventDetails", "EventDetailsOcc", "EditMode");
        const [ok, error] = await S25Util.Maybe(EventMicroService.putEventReservation(this.eventId, payload));
        if (ok) {
            if (ok?.content?.errors || ok?.content?.messages) {
                for (let error of ok?.content?.errors || ok?.content?.messages) {
                    NotificationService.post(error.message);
                }
            } else {
                this.occurrencesService.getEvent(this.eventId, false, ok); //fetch newUpateData
                this.cd.detectChanges();
            }
        }

        this.setLoading(false);
        if (error) return S25Util.showError(error, "There was an error while attempting to update this reservation.");
    }

    checkOverlapping(newDate: Date) {
        if (this.profileCode && this.repeatPatternToggle) return;
        const data = this.normalizeSaveData(false);
        const rsrvs: WSReservations[] = data.items[0].profiles[0].reservations as WSReservations[];
        const duplicates: string[] = this.findDuplicateOcc(rsrvs);
        const newDateDuplicates = duplicates.find(
            (item) => S25Util.date.toS25ISODateTimeStr(item) === S25Util.date.toS25ISODateTimeStr(newDate),
        );
        this.isOverlap.set(newDateDuplicates ? true : false);
        this.cd.detectChanges();
    }

    normalizeSaveData(isDatesChanged: boolean) {
        let event = S25Util.clone(this.occurrencesService.S25Event);
        let keepRepeatPattern: boolean = this.repeatPatternToggle && this.profileCode !== undefined;
        let normalizeSaveData = EventOccUtil.normalizeEventWSData(
            event,
            this.occ,
            this.isCopy,
            false,
            isDatesChanged,
            keepRepeatPattern,
        );
        let data = normalizeSaveData[0].data;
        return data;
    }

    onSharedChange(newVal: boolean, type?: rsrvType, index?: number) {
        this.occ.locations[0][type][index].isShare = newVal;
        if (this.isCopy) return false;
        let updateItem;
        updateItem = this.occ.locations[0][type][index];
        this.updateObject(updateItem, type, Item.Ids.Location);
    }

    async profileModelChange(patternEndChoices: string) {
        const existingOccs: S25Reservation[] = await this.getProfileOccs();
        const tempProfileModel = S25Util.clone(this.profileModel);
        if (patternEndChoices === "date") {
            if (S25Util.date.parse(this.profileModel.throughDate) >= S25Util.date.parse(this.throughDate)) {
                alert(
                    "Please choose a date greater than the initial through date of " +
                        S25Datefilter.transform(tempProfileModel.throughDate, this.dateFormat) +
                        ".",
                );
                return;
            } else {
                tempProfileModel.throughDate = this.throughDate;
            }
        }

        if (patternEndChoices === "iterations") tempProfileModel.endsAfter = this.endsAfter;
        this.newProfileCode = await ProfileUtil.getProfileCodeByModel(tempProfileModel);
        // get new Profile Code getOccurrences
        let newOccList = ProfileUtil.getOccurrences(
            { evStartDt: existingOccs[0].eventStart, evEndDt: existingOccs[0].eventEnd }, //  start and end of  1st segment  rsrv
            tempProfileModel,
        );

        // filter new Profile Code getOccurrences, already existed
        this.newOccsAvail = newOccList.filter(
            (newOcc) =>
                !existingOccs.some(
                    (existingOcc) =>
                        S25Util.date.toS25ISODateStr(existingOcc.eventStart) ===
                            S25Util.date.toS25ISODateStr(newOcc.evStartDt) &&
                        S25Util.date.toS25ISODateStr(existingOcc.eventEnd) ===
                            S25Util.date.toS25ISODateStr(newOcc.evEndDt),
                ),
        );

        if (this.newOccsAvail.length > 0) {
            this.copyOccSaveButtonDisabled = false;
            this.onChangeDate(this.newOccsAvail[0].evStartDt, "start"); // set 1st start date change newOcc
            this.onChangeDate(this.newOccsAvail[0].evEndDt, "end"); // set  1st end date newOcc
        } else {
            this.copyOccSaveButtonDisabled = true;
            this.cd.detectChanges();
        }
    }

    async getProfileOccs() {
        const occs = this.occurrencesService.getOccurrences();
        const profileOccs: S25Reservation[] = await S25ProfileUtil.getProfileReservationsByProfileId(
            occs,
            this.occ.profileId,
        );
        return profileOccs;
    }

    async setAdditionalNewOccs(currentProfile: S25Profile, firstRsrv: WSReservations) {
        const newRsrvId: any = await ReservationService.generateReservationIds(this.newOccsAvail.length);
        let count = 0;
        // Start from the second item of newOccAvail (index 1), 1st one already set when changed iternation or thruDate
        for (const avail of this.newOccsAvail.slice(1)) {
            count++;
            const newReservation = {
                rsrvId: newRsrvId.idSet[count].rsrvId,
                comments: firstRsrv.comments,
                state: firstRsrv.state,
                occurrence: S25Util.date.toS25ISODateStr(avail.evStartDt),
                evStartDt: S25Util.date.toS25ISODateTimeStr(
                    S25Util.date.copyYearMonthDay(avail.evStartDt, new Date(firstRsrv.evStartDt)),
                ),
                evEndDt: S25Util.date.toS25ISODateTimeStr(
                    S25Util.date.copyYearMonthDay(avail.evEndDt, new Date(firstRsrv.evEndDt)),
                ),
                spaces: firstRsrv.spaces,
                resources: firstRsrv.resources,
            };
            currentProfile.reservations.push(newReservation); // Push the new reservation to the array
        }
    }

    async onToggleChange($event: boolean) {
        this.repeatPatternToggle = $event;
        this.showRepeatPatternWarmingMsg = false;
        if (!$event) {
            this.copyOccSaveButtonDisabled = false;
            this.checkOverlapping(this.occ.eventStart);
        } else if ($event && this.newOccsAvail.length === 0) {
            this.copyOccSaveButtonDisabled = true;
        }
        this.cd.detectChanges();
    }
}
